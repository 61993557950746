import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { QUERY } from "@/core/services/store/request.module";
import { find, findIndex } from "lodash";
export default {
  name: "search-members",
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    btxLoading: {
      type: Boolean,
      default: false
    },
    defaultMember: {
      type: Object,
      default: function _default() {
        return {
          id: 0,
          permission: null,
          title: null,
          first_name: null,
          last_name: null,
          phone_number: null,
          display_name: null,
          email: null,
          profile: null
        };
      }
    }
  },
  data: function data() {
    return {
      members: [],
      search: null,
      menu: false,
      loading: false,
      raw_members: [],
      selected_members: [],
      timeout: null,
      timeoutLimit: 500
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        var _this2 = this;

        this.raw_members = param;
        this.$nextTick(function () {
          _this2.init_value(param);
        });
      }
    }
  },
  methods: {
    add_member: function add_member(member) {
      var _this3 = this;

      var index = findIndex(this.raw_members, {
        id: member.id
      });

      if (index < 0) {
        member.permission = "editor";
        this.raw_members.push(member);
      } else {
        this.raw_members.splice(index, 1);
      }

      this.$nextTick(function () {
        _this3.emit_value();
      });
    },
    init_value: function init_value(response) {
      this.selected_members = [];

      for (var i = 0; i < response.length; i++) {
        if (find(this.raw_members, {
          id: response[i].id
        })) {
          this.selected_members.push(response[i].id);
        }
      }
    },
    get_members: function get_members(menu) {
      var _this = this;

      _this.loading = true;
      _this.menu = false;
      clearTimeout(_this.timeout);

      _this.$nextTick(function () {
        _this.timeout = setTimeout(function () {
          _this.$store.dispatch(QUERY, {
            url: "list-users",
            data: {
              search: _this.search
            }
          }).then(function (response) {
            _this.members = response;

            _this.$nextTick(function () {
              _this.menu = menu;

              _this.init_value(response);
            });
          }).catch(function (error) {
            console.log({
              error: error
            });
          }).finally(function () {
            _this.loading = false;
          });
        }, _this.timeoutLimit);
      });
    },
    emit_value: function emit_value() {
      this.$emit("input", this.raw_members);
      this.$emit("change", this.raw_members);
    }
  },
  mounted: function mounted() {
    this.get_members(false);
  }
};