import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Dialog from "@/view/components/Dialog";
import { PATCH } from "@/core/services/store/request.module";
export default {
  name: "empty-confirmation",
  props: {
    emptyDialog: {
      type: Boolean,
      default: false
    }
  },
  components: {
    "empty-dialog": Dialog
  },
  data: function data() {
    return {
      loading: false
    };
  },
  methods: {
    empty_file: function empty_file() {
      var _this = this;

      this.loading = true;
      this.$store.dispatch(PATCH, {
        url: "bin/empty"
      }).then(function () {
        _this.$emit("close", true);

        _this.$emit("success", true);
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this.loading = false;
      });
    }
  }
};