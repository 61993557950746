import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "text-input",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    rules: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: null
    },
    inputRef: {
      type: String,
      default: "textInput"
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    vBindAttrs: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    vOn: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    alphaNumeric: {
      type: Boolean,
      default: false
    },
    appendOuterIcon: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      textinput: null
    };
  },
  watch: {
    value: function value() {
      this.textinput = this.value;
    },
    textinput: function textinput() {
      this.$emit("input", this.textinput);
      this.$emit("change", this.textinput);
    }
  },
  mounted: function mounted() {
    this.textinput = this.value;
  }
};