//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Dialog from "@/view/components/Dialog";
import TagAutoComplete from "@/view/components/TagAutoComplete";
export default {
  name: "btx-manage-tag",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    tagDialog: {
      type: Boolean,
      default: false
    }
  },
  components: {
    "manage-tag": Dialog,
    "tag-auto-complete": TagAutoComplete
  },
  watch: {
    value: function value() {
      this.tags = this.value;
    },
    tagDialog: function tagDialog(param) {
      if (param) {
        this.tags = this.value;
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      tags: []
    };
  },
  methods: {
    update_tags: function update_tags() {
      this.$emit("submit", this.tags);
    }
  },
  mounted: function mounted() {
    this.tags = this.value;
  }
};