import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import { QUERY } from "@/core/services/store/request.module";
export default {
  name: "folder-unlock-dialog",
  props: {
    unlockFolderDialog: {
      type: Boolean,
      default: false
    },
    folderUnlock: {
      type: Object,
      default: function _default() {
        return {
          uuid: null,
          type: null,
          name: null,
          created: {
            datetime: null
          }
        };
      }
    }
  },
  data: function data() {
    return {
      password: null,
      loading: false,
      show_password: false
    };
  },
  watch: {
    unlockFolderDialog: function unlockFolderDialog(param) {
      if (param) {
        this.password = null;
      }
    }
  },
  methods: {
    unlock_folder: function unlock_folder() {
      var _this = this;

      this.loading = true;
      this.$store.dispatch(QUERY, {
        url: "folders/".concat(this.folderUnlock.uuid, "/unlock"),
        data: {
          password: this.password
        }
      }).then(function () {
        _this.$emit("close", true);

        _this.$emit("success", _this.folderUnlock);
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this.loading = false;
      });
    }
  },
  components: {
    Dialog: Dialog,
    "text-input": TextInput
  }
};