//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import objectPath from "object-path";
export default {
  name: "show-value",
  title: "Show Value",
  props: {
    object: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    },
    objectKey: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: null
    },
    truncate: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    tooltipValue: {
      type: String,
      default: null
    }
  },
  methods: {
    value: function value() {
      if (objectPath.has(this.object, this.objectKey)) {
        if (objectPath.get(this.object, this.objectKey)) {
          return objectPath.get(this.object, this.objectKey);
        } else {
          return "<em class=\"text-muted\">No ".concat(this.label, "</em>").toLowerCase();
        }
      } else {
        return "<em class=\"text-muted\">No ".concat(this.label, "</em>").toLowerCase();
      }
    },
    getTooltipValue: function getTooltipValue() {
      if (objectPath.has(this.object, this.tooltipValue)) {
        if (objectPath.get(this.object, this.tooltipValue)) {
          return objectPath.get(this.object, this.tooltipValue);
        } else {
          return "<em class=\"text-muted\">No ".concat(this.label, "</em>").toLowerCase();
        }
      } else {
        return "<em class=\"text-muted\">No ".concat(this.label, "</em>").toLowerCase();
      }
    }
  }
};