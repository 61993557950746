import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import { PATCH } from "@/core/services/store/request.module";
export default {
  name: "rename-dialog",
  props: {
    renameDialog: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "other"
    },
    toBeRename: {
      type: Object,
      default: function _default() {
        return {
          uuid: null,
          type: null,
          name: null,
          created: {
            datetime: null
          }
        };
      }
    }
  },
  data: function data() {
    return {
      rename_model: null
    };
  },
  watch: {
    toBeRename: function toBeRename() {
      this.init_rename();
    }
  },
  mounted: function mounted() {
    this.init_rename();
  },
  methods: {
    init_rename: function init_rename() {
      if (this.toBeRename.name) {
        var name = this.toBeRename.name;

        if (name) {
          this.rename_model = name.split(".").slice(0, -1).join(".");
        }

        if (!this.rename_model) {
          this.rename_model = this.toBeRename.name;
        }
      }
    },
    rename_file: function rename_file() {
      var _this = this;

      this.loading = true;
      var requestURL = "files/".concat(this.toBeRename.uuid, "/rename");

      if (this.type == "tag") {
        requestURL = "tag-listing/".concat(this.toBeRename.uuid, "/rename");
      }

      this.$store.dispatch(PATCH, {
        url: requestURL,
        data: {
          name: this.rename_model
        }
      }).then(function () {
        _this.$emit("close", true);

        _this.$emit("success", true);
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this.loading = false;
      });
    }
  },
  components: {
    Dialog: Dialog,
    "text-input": TextInput
  }
};