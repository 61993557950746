var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"dialog":_vm.shareDialog,"dialog-width":_vm.dialogWidth},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Share with people")]},proxy:true},{key:"body",fn:function(){return [_c('v-container',[_c('v-row',{staticClass:"mx-0 members-list"},[_c('v-col',{attrs:{"cols":""}},[_c('search-members',{attrs:{"multiple":"","default-member":_vm.defaultMember},model:{value:(_vm.members),callback:function ($$v) {_vm.members=$$v},expression:"members"}}),_c('div',{staticClass:"mt-5",staticStyle:{"max-height":"calc(100vh - 300px)","min-height":"calc(100vh - 400px)","overflow":"auto"}},[_c('v-list',{attrs:{"two-line":""}},[(_vm.defaultMember)?_c('v-list-item',{class:{
									'border-bottom-light-grey': _vm.members.length,
								}},[_c('v-list-item-action',{staticClass:"drive-action-btn"},[_c('v-btn',{attrs:{"icon":"","color":"red lighten-1","disabled":""}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-list-item-avatar',[_c('img',{attrs:{"src":_vm.defaultMember.profile ? _vm.defaultMember.profile : _vm.$assetURL('media/users/blank.png')}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.defaultMember.display_name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.defaultMember.email)}})],1),_c('v-list-item-action',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"blue darken-4 white--text","text":""}},[_vm._v(" Owner ")])],1)],1):_vm._e(),_vm._l((_vm.members),function(item,index){return _c('v-list-item',{key:index,class:{
									'border-bottom-light-grey': _vm.members[index + 1],
								},attrs:{"value":item.id}},[_c('v-list-item-action',{staticClass:"drive-action-btn"},[_c('v-btn',{attrs:{"icon":"","color":"red lighten-1"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.delete_member(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-list-item-avatar',[_c('img',{attrs:{"src":item.profile ? item.profile : _vm.$assetURL('media/users/blank.png')}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.display_name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.email)}})],1),(false)?_c('v-list-item-action',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":"blue darken-4 white--text"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.permission)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-down")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){item.permission = 'viewer'}}},[_c('v-list-item-title',[_vm._v("Viewer")])],1),_c('v-list-item',{on:{"click":function($event){item.permission = 'editor'}}},[_c('v-list-item-title',[_vm._v("Editor")])],1)],1)],1)],1):_vm._e()],1)})],2)],1)],1)],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{attrs:{"depressed":"","tile":""},on:{"click":function($event){return _vm.$emit('close', true)}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"blue darken-4 white--text",attrs:{"depressed":"","tile":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Done")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }