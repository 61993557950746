import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { head } from "lodash";
import Dialog from "@/view/components/Dialog";
import NewFolderDialog from "@/view/components/NewFolderDialog";
import { GET, PATCH } from "@/core/services/store/request.module";
export default {
  name: "folder-dialog",
  props: {
    moveDialog: {
      type: Boolean,
      default: false
    },
    toBeMove: {
      type: Object,
      default: function _default() {
        return {
          uuid: null,
          type: null,
          name: null,
          created: {
            datetime: null
          }
        };
      }
    }
  },
  watch: {
    moveDialog: function moveDialog(param) {
      if (param) {
        this.init();
      }
    }
  },
  data: function data() {
    return {
      tree: [],
      items: [],
      loading: false,
      t_loading: false,
      new_folder_dialog: false,
      output: {
        id: 0,
        name: null,
        children: []
      }
    };
  },
  methods: {
    init: function init() {
      this.tree = [];
      this.items = [];
      this.loading = false;
      this.new_folder_dialog = false;
      this.output = {
        id: 0,
        name: null,
        children: []
      };
      this.get_folder_list();
    },
    update_tree: function update_tree(param) {
      this.output = head(param);
      this.$emit("input", this.output);
      this.$emit("change", this.output);
    },
    get_folder_list: function get_folder_list() {
      var _this = this;

      this.t_loading = true;
      this.$store.dispatch(GET, {
        url: "folders"
      }).then(function (output) {
        _this.items = output;
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this.t_loading = false;
      });
    },
    patch_folder_move: function patch_folder_move() {
      var _this2 = this;

      this.loading = true;
      this.$store.dispatch(PATCH, {
        url: "files/".concat(this.toBeMove.uuid, "/move"),
        data: this.output
      }).then(function () {
        _this2.$emit("close", true);

        _this2.$emit("success", true);
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this2.loading = false;
      });
    }
  },
  components: {
    Dialog: Dialog,
    "new-folder-dialog": NewFolderDialog
  },
  mounted: function mounted() {
    this.init();
  }
};