import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { GET } from "@/core/services/store/request.module";
import { cloneDeep } from "lodash";
export default {
  name: "tag-autocomplete-list",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      item_color: "#e2b910",
      loading: false,
      btxLoading: false,
      disabled: false,
      activator: null,
      attach: null,
      colors: ["#0dd3d3", "#e2b910", "#61bfc1", "#f28840", "#d359aa", "#83b727", "#b58a61", "#ffac14", "#48b7b4", "#f78b84", "#57c065", "#018ecd", "#0995ba", "#33c5f2", "#ff65cb", "#b79d7d", "#3b92ff", "#ba83f2", "#d3854a", "#99a8bd"],
      editing: null,
      editingIndex: -1,
      items: [],
      nonce: 1,
      menu: false,
      model: [],
      x: 0,
      search: null,
      y: 0
    };
  },
  watch: {
    value: function value() {
      this.init_tags();
    },
    model: function model(val, prev) {
      if (val.length === prev.length) return;
      this.emit_data();
    }
  },
  methods: {
    emit_data: function emit_data() {
      var _this = this;

      this.$nextTick(function () {
        _this.$emit("input", _this.model);

        _this.$emit("change", _this.model);
      });
    },
    create_tag: function create_tag() {
      this.items.push({
        color: this.item_color,
        text: this.search,
        total: 0
      });
      this.model.push({
        color: this.item_color,
        text: this.search
      });
      this.search = null;
      this.init_color();
      this.emit_data();
    },
    init_color: function init_color() {
      this.item_color = this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    select_color: function select_color(param) {
      this.item_color = param;
    },
    init_tags: function init_tags() {
      if (this.value && this.value.length > 0) {
        this.model = cloneDeep(this.value);
        this.nonce = this.value.length;
      }
    },
    get_tags: function get_tags() {
      var _this2 = this;

      this.loading = true;
      this.$store.dispatch(GET, {
        url: "tags"
      }).then(function (response) {
        console.log({
          response: response
        });
        _this2.items = response;

        _this2.$nextTick(function () {
          _this2.init_tags();
        });
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this2.loading = false;
      });
    },
    edit: function edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter: function filter(item, queryText, itemText) {
      if (item.header) return false;

      var hasValue = function hasValue(val) {
        return val != null ? val : "";
      };

      var text = hasValue(itemText);
      var query = hasValue(queryText);
      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
    }
  },
  mounted: function mounted() {
    this.get_tags();
    this.init_color();
  }
};