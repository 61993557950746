import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Dialog from "@/view/components/Dialog";
import SearchMembers from "@/view/components/SearchMembers";
import { toSafeInteger, findIndex } from "lodash";
export default {
  model: {
    prop: "value",
    event: "input"
  },
  data: function data() {
    return {
      members: []
    };
  },
  components: {
    Dialog: Dialog,
    "search-members": SearchMembers
  },
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    shareDialog: {
      type: Boolean,
      default: false
    },
    defaultMember: {
      type: Object,
      default: function _default() {
        return {
          id: 0,
          permission: null,
          title: null,
          first_name: null,
          last_name: null,
          phone_number: null,
          display_name: null,
          email: null,
          profile: null
        };
      }
    }
  },
  watch: {
    members: {
      deep: true,
      immediate: true,
      handler: function handler() {
        this.emit_value();
      }
    },
    value: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.members = param;
      }
    }
  },
  methods: {
    emit_value: function emit_value() {
      this.$emit("input", this.members);
      this.$emit("change", this.members);
    },
    submit: function submit() {
      this.$emit("submit", this.members);
      this.$emit("close", true);
    },
    delete_member: function delete_member(member) {
      var index = findIndex(this.members, {
        id: member.id
      });
      this.members.splice(index, 1);
    }
  },
  computed: {
    dialogWidth: function dialogWidth() {
      return toSafeInteger(document.body.clientWidth / 100 * 50);
    }
  }
};