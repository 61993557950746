import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { map } from "lodash";
import { GET } from "@/core/services/store/request.module";
export default {
  name: "top-search-bar",
  props: {
    placeholder: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      search_sync: null,
      search_model: null,
      is_focused: false,
      is_loading: true,
      tags: [],
      users: [],
      items: []
    };
  },
  watch: {
    search_sync: function search_sync(param) {
      if (param) {
        if (param.charAt(0) == "#") {
          this.items = this.tags;
        } else if (param.charAt(0) == "@") {
          this.items = this.users;
        } else {
          this.items = [];
        }
      } else {
        this.items = [];
      }
    }
  },
  methods: {
    row_select: function row_select(row) {
      console.log({
        row: row
      });
    },
    clear_search: function clear_search() {
      this.items = [];
      this.search_sync = null;
      this.search_model = null;
    },
    start_search: function start_search() {
      if (this.search_sync) {
        if (this.search_sync.charAt(0) == "#" || this.search_sync.charAt(0) == "@") {
          return false;
        }
      }

      if (this.search_model) {
        if (this.search_model.charAt(0) == "#" || this.search_model.charAt(0) == "@") {
          return false;
        }
      }

      console.log({
        search_sync: this.search_sync
      });
      console.log({
        search_model: this.search_model
      }); //
    },
    get_search_options: function get_search_options() {
      var _this = this;

      this.$store.dispatch(GET, {
        url: "search/options"
      }).then(function (response) {
        _this.tags = map(response.tags, "name");
        _this.users = map(response.users, "display_name");
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this.is_loading = false;
      });
    }
  },
  mounted: function mounted() {
    this.get_search_options();
  },
  computed: {
    raw_placeholder: function raw_placeholder() {
      return this.is_focused ? "Start Typing then press enter" : "Search " + this.placeholder + "...";
    }
  }
};