import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import { POST } from "@/core/services/store/request.module";
export default {
  name: "new-folder-dialog",
  props: {
    newFolderDialog: {
      type: Boolean,
      default: false
    },
    linkedId: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      loading: false,
      new_folder_model: null
    };
  },
  methods: {
    new_folder_create: function new_folder_create() {
      var _this = this;

      this.loading = true;
      this.$store.dispatch(POST, {
        url: "folders/create",
        data: {
          name: this.new_folder_model,
          linked_id: this.linkedId
        }
      }).then(function () {
        _this.$emit("close", true);

        _this.$emit("success", true);
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this.loading = false;
      });
    }
  },
  components: {
    Dialog: Dialog,
    "text-input": TextInput
  }
};