import _slicedToArray from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.pad-start.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "date-picker",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: [Date, String],
      default: null
    },
    hidePrefixIcon: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: [Date, String],
      default: null
    },
    maxDate: {
      type: [Date, String],
      default: null
    },
    rules: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: "Date"
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    contentClass: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    topDense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      date: null,
      datepicker: false,
      dateFormatted: null
    };
  },
  watch: {
    minDate: function minDate() {
      this.date = null;
    },
    maxDate: function maxDate() {
      this.date = null;
    },
    date: function date(new_value, old_value) {
      if (new_value != old_value) {
        this.emitValue();
      }
    },
    value: function value() {
      this.date = this.value;
    }
  },
  methods: {
    emitValue: function emitValue() {
      this.dateFormatted = this.formatDate(this.date);
      this.$emit("input", this.date);
      this.$emit("change", this.date);
    },
    formatDate: function formatDate(date) {
      if (!date) return null;

      var _date$split = date.split("-"),
          _date$split2 = _slicedToArray(_date$split, 3),
          year = _date$split2[0],
          month = _date$split2[1],
          day = _date$split2[2];

      return "".concat(day, "/").concat(month, "/").concat(year);
    },
    parseDate: function parseDate(date) {
      if (!date) return null;

      var _date$split3 = date.split("/"),
          _date$split4 = _slicedToArray(_date$split3, 3),
          day = _date$split4[0],
          month = _date$split4[1],
          year = _date$split4[2];

      return "".concat(year, "-").concat(month.padStart(2, "0"), "-").concat(day.padStart(2, "0"));
    }
  },
  computed: {
    btxPlaceholder: function btxPlaceholder() {
      return this.placeholder + " [DD/MM/YYYY]";
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.date = this.value;
    this.$nextTick(function () {
      _this.emitValue();
    });
  }
};