import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "textarea-input",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    rows: {
      type: Number,
      default: 3
    },
    rules: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    label: {
      type: String,
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autoGrow: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      textareainput: null
    };
  },
  methods: {
    block_enter: function block_enter(event) {
      event.preventDefault();
    }
  },
  watch: {
    value: function value() {
      this.textareainput = this.value;
    },
    textareainput: function textareainput() {
      this.$emit("input", this.textareainput);
    }
  },
  mounted: function mounted() {
    this.textareainput = this.value;
  }
};