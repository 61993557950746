import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Dialog from "@/view/components/Dialog";
import { PATCH } from "@/core/services/store/request.module";
export default {
  name: "restore-confirmation",
  props: {
    restoreDialog: {
      type: Boolean,
      default: false
    },
    toBeRestore: {
      type: Object,
      default: function _default() {
        return {
          uuid: null,
          type: null,
          name: null,
          created: {
            datetime: null
          }
        };
      }
    }
  },
  components: {
    "restore-dialog": Dialog
  },
  data: function data() {
    return {
      loading: false
    };
  },
  methods: {
    restore_file: function restore_file() {
      var _this = this;

      this.loading = true;
      this.$store.dispatch(PATCH, {
        url: "files/".concat(this.toBeRestore.uuid, "/restore")
      }).then(function () {
        _this.$emit("close", true);

        _this.$emit("success", true);
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this.loading = false;
      });
    }
  }
};